@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.modal {
  @include fixedSize(400px, 'max-content');
  @include min-1440-break {
    @include fixedSize(482px, 'max-content');
  }

  div[id='modal-content-container'] {
    padding: 16px;
    gap: 16px;
    @include fixedSize(100%, 100%);
    @include min-1440-break {
      padding: 20px;
      gap: 20px;
      @include fixedSize(100%, 100%);
    }
  }
}

.header-left {
  display: flex;
  align-items: center;

  gap: 8px;

  @include min-1440-break {
    gap: 10px;
  }

  .icon {
    span[id='label-icon'] {
      color: var(--global-purple600);
    }
  }
}

.content {
  background-color: var(--cst-cards-checkout-info-background);
  border: 2px solid var(--cst-cards-checkout-info-outline);
  padding: 16px;
  border-radius: var(--cornerradius12);
  gap: 12px;

  @include min-1440-break {
    gap: 14px;
  }

  &-items {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: scroll;
    padding-right: 16px;

    max-height: 145px;

    @include min-1440-break {
      gap: 14px;
      max-height: 153px;
    }
  }
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .amount {
    @include typography-base;
    @include weight-semi-bold;

    @include min-1440-break {
      @include typography-m;
    }
  }

  .total-info {
    display: flex;
    align-items: center;
    gap: 10px;

    .amount-text {
      @include typography-s;
      @include weight-medium;

      @include min-1440-break {
        @include typography-m;
      }
    }
  }
}
