@use '@queries' as *;
@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;

.label--icon {
  span[id='label-icon'] {
    color: var(--global-purple600);
  }
}

.container--add-item {
  @include fixedSize(100%, 100%);
  div[id='modal-content-container'] {
    padding: 16px;
    gap: 16px;
    @include fixedSize(100%, 100%);
    @include min-1440-break {
      padding: 20px;
      gap: 20px;
      @include fixedSize(100%, 100%);
    }
  }

  .items-list {
    display: flex;
    flex: 1;
    overflow-y: auto;
    padding: 0 8px 0 0;
    @include fixedSize(308px, 290px);

    @include min-1440-break {
      @include fixedSize(368px, 336px);
    }

    .items {
      margin: 0;

      padding: 0 8px 0 0;
      display: flex;
      flex-direction: column;
      gap: 14px;

      @include min-1440-break {
        gap: 18px;
      }

      .item {
        @include fixedSize(296px, 78px);

        @include min-1440-break {
          @include fixedSize(356px, 88px);
        }
      }
    }
  }
}

.modal--add-item {
  @include fixedSize(340px, min(417px, max-content));
  @include min-1440-break {
    @include fixedSize(408px, min(490px, max-content));
  }
}

.modal {
  @include fixedSize(360px, min(428px, max-content));
  @include min-1440-break {
    @include fixedSize(436px, min(565px, max-content));
  }
}

.skeleton {
  height: 64px;

  @include min-428-break {
    height: 80px;
  }
}

.not-found {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 14px;

  @include min-1440-break {
    gap: 18px;
  }
}

.not-found-text-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @include fixedSize(226px, 100%);
  align-self: center;

  .title {
    @include typography-base;
    @include weight-semi-bold;
    color: var(--cst-side-bar-shop-text);

    @include min-428-break {
      @include typography-xl;
    }

    @include min-744-break {
      @include typography-xl;
    }
  }

  .subtitle {
    text-align: center;
    @include typography-s;
    @include weight-medium;

    color: var(--cst-side-bar-shop-descriprion);

    @include min-428-break {
      @include typography-m;
    }

    @include min-744-break {
      @include typography-m;
    }
  }
}

.not-found-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .title {
    @include typography-base;
    @include weight-semi-bold;
    color: var(--cst-side-bar-shop-text);

    @include min-1440-break {
      @include typography-m;
    }
  }

  .subtitle {
    text-align: center;
    @include typography-xs;
    @include weight-medium;

    color: var(--cst-side-bar-shop-descriprion);

    @include min-1440-break {
      @include typography-s;
    }
  }
}

.items__sheet-header-container {
  display: flex;
  align-items: center;
  gap: 8px;

  @media (min-width: 428px) {
    gap: 12px;
  }
}

.items__sheet-container {
  display: flex;
  flex-direction: column;
  max-height: 274px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  gap: 6px;
  @include hideScrollbar;
  @media (min-width: 428px) {
    gap: 8px;

    max-height: 344px;
  }

  @media (min-width: 744px) {
    min-width: 400px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.sheet {
  min-height: 388px;

  @include min-428-break {
    min-height: 473px;
  }

  .react-modal-sheet-content {
    min-height: inherit;
  }
}

.not-found-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 275px;

  overflow: hidden;
  @include min-428-break {
    min-height: 344px;
  }
}
