@use '@fixed-size' as *;
@use '@typography' as *;
@use '@button' as *;
@use '@queries' as *;

.btn {
  @include singleFixedSize(44px);

  @include min-428-break {
    @include singleFixedSize(48px);
  }

  @include min-744-break {
    @include singleFixedSize(44px);
  }
}

.icon {
  color: #6941c699;
  @include singleFixedSize(20px);

  @include min-428-break {
    @include singleFixedSize(22px);
  }

  @include min-744-break {
    @include singleFixedSize(20px);
  }
}
