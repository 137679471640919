@use '@queries' as *;
@use '@fixed-size' as *;

.modal {
  @include fixedSize(360px, min(428px, max-content));
  @include min-1440-break {
    @include fixedSize(436px, min(565px, max-content));
  }
}

.btn {
  span:first-child {
    @include singleFixedSize(20px);

    @include min-1440-break {
      @include singleFixedSize(22px);
    }
  }
}
