@use '@/shared/ui/button/adaptive' as *;
@use '@button' as *;
@use '@fixed-size' as *;
@use '@queries' as *;
@use '@typography' as *;

.action-btn {
  @include buttonSizes('34', '42', '40', '34', '42', '40');

  span[id='btn-icon'],
  svg {
    @include singleFixedSize(16px);

    @include min-428-break {
      @include singleFixedSize(18px);
    }
  }
}

.quantity-container {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 4px;
  button,
  input {
    @include typography-s;
    @include weight-bold;
    border-radius: var(--cornerradius8);
    @include singleFixedSize(34px);
    span {
      @include singleFixedSize(14px);
    }
  }

  input {
    flex: 1;
    @include fixedSize(inherit, 34px);
  }
  @include min-428-break {
    button,
    input {
      @include typography-m;
      border-radius: var(--cornerradius10);
      @include singleFixedSize(42px);
      span {
        @include singleFixedSize(18px);
      }
    }

    input {
      flex: 1;
      @include fixedSize(inherit, 42px);
    }
  }
  @include min-744-break {
    button,
    input {
      @include typography-base;
      @include singleFixedSize(40px);
    }

    input {
      flex: 1;
      @include fixedSize(inherit, 40px);
    }
  }
}
