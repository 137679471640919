@use '@/shared/ui/button/adaptive' as *;
@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  display: flex;
  flex-direction: column;
}

.modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 22px;

  @include min-1024-break {
    gap: 8px;
  }

  @include min-1440-break {
    gap: 28px;
  }
}

.description {
  width: 100%;
  padding: 14px;
  border-radius: var(--cornerradius10);
  background-color: rgba(250, 250, 250, 1);
  border: 2px solid rgba(246, 246, 246, 1);
}

.divider_container {
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: center;
  p {
    @include typography-xs;
    color: var(--cst-authorization-secondarytext);
    @include min-1024-break {
      @include typography-s;
    }
    @include min-1440-break {
      @include typography-base;
    }
  }
}

.divider {
  min-width: 95px;
  max-width: 95px;
}

.social-container {
  width: 100%;
  display: flex;
  gap: 8px;
}

.inputs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @include min-428-break {
    gap: 12px;
  }
  @include min-1024-break {
    gap: 8px;
  }
  @include min-1440-break {
    gap: 12px;
  }
}

.terms-container {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  @include min-1440-break {
    height: 28px;
  }
}

.bottom-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include min-428-break {
    gap: 10px;
  }
  @include min-1024-break {
    gap: 8px;
  }
  @include min-1440-break {
    gap: 10px;
  }
  &-button {
    @include buttonSizes('38', '46', '46', '42', '50', '50');
  }
}

.link {
  color: var(--cst-authorization-quaternarytext);
  cursor: pointer;
}

.social_btn {
  span:nth-child(2) {
    display: none;
  }
  @include min-1024-break {
    span:nth-child(2) {
      display: block;
    }
  }
}

.center-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button-icon {
  @include singleFixedSize(18px);
  @include min-428-break {
    @include singleFixedSize(20px);
  }
}

.text-regular {
  text-align: center;
  color: #8f8f8f;
  @include typography-xs;
  @include min-428-break {
    @include typography-s;
  }
  @include min-1024-break {
    @include typography-s;
    letter-spacing: 0.5px;
  }
  @include min-1440-break {
    height: 21px;
    @include typography-base;
  }
}

.link {
  color: #6941c6;
}

.bottom-slot {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
  gap: 2px;
  align-self: center;
  padding-top: 10px;
  @include min-1440-break {
    height: 21px;
    padding-top: 2px;
  }
}

.link {
  color: #6941c6;
  cursor: pointer;
}

.forgot-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 4px 0 0 0;
  @include min-1024-break {
    padding: 0 0 4px 0;
    margin-top: -4px;
  }
  @include min-1440-break {
    padding: 4px 0 0 0;
    margin: 0;
  }
}

.forgot-text {
  cursor: pointer;
  letter-spacing: 0.5px;
  @include weight-medium;
  color: #d0d0d0 !important;
  @include typography-s;
  @include min-428-break {
    @include typography-base;
  }
  @include min-1024-break {
    @include typography-s;
  }
  @include min-1440-break {
    @include typography-base;
  }
}

.promo-title p:nth-child(2) {
  @include typography-base;
  @include weight-medium;
  color: var(--alias-modal-on-primary-text-secondary);

  @include min-428-break {
    @include typography-m;
  }

  @include min-1024-break {
    @include typography-base;
  }

  @include min-1440-break {
    @include typography-m;
  }
}
